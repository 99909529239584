
*{
  font-family: 'Poppins', sans-serif;
}
.container{
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.header-styling{
  background-image: url(./assets/heder1.jpg);
}
.logo-navbar {
  width: 70px;
}
.header-icons a{
  font-size: 20px
}
.phone-img-f-s article{
  background-image: url(./assets/heder\ 3.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
}
.first-section{
  background-image: unset;
}
.form-styling {
  background-image: url(./assets/marbel.jpg);
  max-width: 350px !important;
  border: 1px solid #e0d9d9;
}
.form-styling div label{
  font-size: 14px;
}
.form-styling div input{
  border: none;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 1px 1px 4px gray;
}
.form-styling div input::placeholder{
  font-size: 14px;
}
.form-styling div textarea{
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 100px !important;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 1px 1px 4px gray;
}
.form-styling div textarea::placeholder{
  font-size: 14px;
}
/* second section */
.second-section {
  margin-top: 70px;
}
.second-section main h2{
  font-size: 26px;
  line-height: 1.2;
  max-width: 300px;
}
.second-section main p{
  line-height: 1.8;
  max-width: 970px;
  color: #666666;
  }
/* end second section  */


/* third section card */

.third-section-card h3{
  font-size: 20px;
}

.third-section-card p{
  font-size: 16px;
  opacity: 0.8;
}
.third-section-card{
  border-bottom: 1px solid #E6E5E5;
}

.last-card{
  border: none !important;
}
/* end third section  */

/* First Swiper Styling  */
.first-swiper{
  height: 200px;
}
.first-swiper .swiper-button-prev::after,
.first-swiper .swiper-button-next::after
{
  color: white;
  font-size: 25px;
  font-weight: 800;
}
/* end First Swiper Styling  */

/* Error Page styling  */
.error-404 {
  height: 80vh;
}
.error-404 h1{
  font-size: 80px;
}
.error-404 a{
  background-color: black;
  color: white;
  border: none;
  outline: none;
  text-decoration: none;
}
.error-404 a:hover{
  transition-duration: 0.3s;
  transition-delay: 0.1s;
  font-size: 20px !important;
  color: white;
}
/* end error page styling  */

/* off canvas  */
.off-canvas-logo{
  width: 80px;
}
/* end off canvas */

/* Realisation Styling  */
.realisation-styling main p{
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  text-align: center;
  color: #666666;
  max-width: 700px;
}

/* end Realisation styling  */
.partenaire-icon{
  height: 60px;
  width: 150px;
}
.whatsap-iconn{
  position: fixed;
  right: 4%;
  bottom: 10%;
  width: 50px;
  z-index: 2001;
}
.btn-wtsp-redi{
  border: none;
  height: 60px;
  width: 380px;
}
.demande-devis-bnt-animation{
  height: 50px;
  width: 280px !important;
  white-space: nowrap;
}
.btn-wtsp-redi a,.demande-devis-bnt-animation{
  animation: wtspbtn 2s infinite;
}
.googlmap-styling{
  height: 300px ;
  width: 100%;
}
.partenaire-swiper{
  height: 150px;
}
.header-phone{
  animation: phoneheader 2s infinite ease-in-out;
}
@keyframes wtspbtn {
  0% {
    font-size: 16px;
  }
  50%{
    font-size: 18px;
  }
  100%{
    font-size: 16px;
  }
}
@keyframes phoneheader {
  0% {
    transform: rotate(30deg);
  }
  50%{
    transform: rotate(0deg );
  }
  100%{
    transform: rotate(30deg );
  }
}

/*contactez Nous styling*/
.Contactez-nous-styling{
  background-image: url(./assets/appelnous.jpg);
  background-size: 100%;
}
/* end contactez nous */

/* footer styling  */
footer{
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url(./assets//appelnous.jpg);
  background-size: 100%;
}
.footer-logo{
  width: 100px;
  background-color: whitesmoke;
}
.border-in-footer{
  border-bottom: 1px solid #E6E5E5;
}
/* end footer styling  */

@media (min-width: 576px) { 
  
 }

@media (min-width: 768px) { 
  .first-section{
    background-image: url(./assets/heder\ 3.jpg);
    height: 600px;
    background-repeat: no-repeat;
  }
  .form-styling {
    max-width: 400px;
  }
  .before-last-card{
    border: none;
  }
  .border-in-footer{
    border-bottom: unset;
  }
  .first-swiper{
    height: 400px;
  }
  
}



@media (min-width: 992px) { 
  .first-section{
    height: 600px;
  }
  .third-section-card{
    border-bottom: unset;
    border-right: 1px solid #E6E5E5;
  }
  .first-swiper{
    height: 600px;
  }
  .googlmap-styling{
    height: 100% ;
    width: 100%;
  }
 }

@media (min-width: 1200px) { 

 }
